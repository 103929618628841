// import React, { useState, useEffect } from "react";
// import { Badge, Drawer, List, Typography, Button } from "antd";
// import { BellOutlined, CloseOutlined } from "@ant-design/icons";
// import { useDispatch, useSelector } from "react-redux";
// import { loginDetailsSelector } from "store/selectors/app.selector";
// import RegistrationService from "../../services/registration.service";
// import config from "../../../src/services/constant";
// import { OPERTION_CODE } from "Constants/enum";

// const { Text } = Typography;

// const Notification = () => {
//   const dispatch = useDispatch();
//   const loginDetails = useSelector(loginDetailsSelector);
//   const [drawerOpen, setDrawerOpen] = useState(false);
//   const [notifications, setNotifications] = useState([]);

//   // Fetch notifications from API
//   useEffect(() => {
//     const fetchNotifications = async () => {
//       try {
//         const payload = {
//           operation_code: OPERTION_CODE.NOTIFICATIONS,
//           cust_id: loginDetails?.custId || "",
//         };
//         const response = await RegistrationService.postRequest(
//           payload,
//           config.USER_NOTIFICATIONS
//         );

//         if (response.data.response) {
//           setNotifications(response.data.response);
//         }
//       } catch (err) {
//         console.error("Error fetching notifications:", err);
//       }
//     };

//     if (loginDetails?.custId) {
//       fetchNotifications();
//     }
//   }, [loginDetails]);

//   const toggleDrawer = () => setDrawerOpen(!drawerOpen);

//   return (
//     <div>
//       <Badge count={notifications.length} size="small">
//         <BellOutlined
//           className="text-gray-800 text-xl cursor-pointer"
//           onClick={toggleDrawer}
//         />
//       </Badge>

//       <Drawer
//         title="Notifications"
//         placement="right"
//         closable={false}
//         onClose={toggleDrawer}
//         open={drawerOpen}
//         width="300px"
//       >
//         <button
//           onClick={toggleDrawer}
//           className="absolute top-2 right-2 text-2xl"
//           aria-label="Close Drawer"
//         >
//           <CloseOutlined />
//         </button>

//         <List
//           itemLayout="horizontal"
//           dataSource={notifications}
//           renderItem={(notification) => (
//             <List.Item>
//               <List.Item.Meta
//                 title={<Text>{notification.title}</Text>}
//                 description={<Text>{notification.message}</Text>}
//               />
//             </List.Item>
//           )}
//         />
//         <Button
//           type="primary"
//           onClick={() => setNotifications([])}
//           style={{ marginTop: "10px" }}
//         >
//           Clear All
//         </Button>
//       </Drawer>
//     </div>
//   );
// };

import React, { useState, useEffect } from 'react';
import { List, Avatar, Typography, Badge, Button, Modal } from 'antd';
import { BellOutlined } from '@ant-design/icons';

const { Text } = Typography;

const Notification = () => {
  const initialNotifications = [
    { id: 1, message: 'Your request has been approved', time: '2 mins ago', details: 'Your request for account verification has been approved by the admin.' },
    { id: 2, message: 'New message from support team', time: '10 mins ago', details: 'The support team has responded to your query regarding the account balance.' },
    { id: 3, message: 'Your password has been changed', time: '1 hour ago', details: 'Your password was successfully updated at 2:00 PM today.' },
    { id: 4, message: 'Account settings updated successfully', time: '3 hours ago', details: 'Your account settings, including contact information, have been updated.' },
  ];

  const [notifications, setNotifications] = useState(initialNotifications);
  const [showNotifications, setShowNotifications] = useState(false);
  const [selectedNotification, setSelectedNotification] = useState(null);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const newNotification = {
        id: Date.now(), 
        message: `New notification received at ${new Date().toLocaleTimeString()}`,
        time: 'Just now',
        details: 'This is a placeholder for the notification details.',
      };

      setNotifications((prevNotifications) => {
        const updatedNotifications = [newNotification, ...prevNotifications]; 

        if (updatedNotifications.length > 4) {
          updatedNotifications.pop(); 
        }

        return updatedNotifications;
      });

    }, 120000); 

    return () => clearInterval(intervalId);
  }, []);

  const toggleNotifications = () => {
    setShowNotifications(!showNotifications);
  };

  const handleNotificationClick = (notification) => {
    setSelectedNotification(notification);
  };

  const handleCloseModal = () => {
    setSelectedNotification(null);
  };

  return (
    <div>
      <Badge count={notifications.length}>
        <BellOutlined
          className="text-gray-800 text-xl cursor-pointer"
          onClick={toggleNotifications}
        />
      </Badge>

      {showNotifications && (
        <div className="notification-drawer" style={{ position: 'fixed', top: '60px', right: '20px', backgroundColor: 'white', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)', borderRadius: '8px', width: '300px', maxHeight: '400px', overflowY: 'auto', zIndex: 999 }}>
          <List
            itemLayout="horizontal"
            dataSource={notifications}
            renderItem={(item) => (
              <List.Item onClick={() => handleNotificationClick(item)} style={{ cursor: 'pointer', padding: '10px' }}>
                <List.Item.Meta
                  avatar={<Avatar size="small" icon={<BellOutlined />} />}
                  title={<Text>{item.message}</Text>}
                  description={item.time}
                />
              </List.Item>
            )}
          />
          <Button type="link" onClick={toggleNotifications} style={{ margin: '10px', display: 'block', textAlign: 'center' }}>Close</Button>
        </div>
      )}

      {selectedNotification && (
        <Modal
          title="Notification Details"
          visible={true}
          onCancel={handleCloseModal}
          footer={[
            <Button key="close" onClick={handleCloseModal}>
              Close
            </Button>
          ]}
        >
          <Typography.Title level={4}>{selectedNotification.message}</Typography.Title>
          <Text>{selectedNotification.details}</Text>
        </Modal>
      )}
    </div>
  );
};

export default Notification;
