// import React, { useState, useCallback,useEffect } from "react";
// import { useNavigate } from "react-router-dom";

// import "./layout.css";
// import SideBar from "./sidebar";
// import TopBanner from "./topbanner";
// import Config from "../../environment"; 

// import { authTokenSelector } from "../../store/selectors/auth.selector";
// import { useSelector, useDispatch } from "react-redux";
// import Timer from "./Timer";


// export default function Layout({ children, }) {
//     const authToken = useSelector(authTokenSelector);
//     const navigate = useNavigate();
   



//     useEffect(() => {
//         if (authToken === null) {
//           navigate("/login");
//         }
//       }, [authToken]);
//     return (
//         <div className="app-container">
//             <div className="top-banner">
//                 <TopBanner/>
//             </div>
//             <div className="render-section">
//                 <div className="left-section">
//                     <SideBar />
//                 </div>
//                 <div className="right-section">
//                     {children}
//                 </div>
            
//             </div>
//             <div>
          
//              {authToken && (
//           <Timer isLoggedIn={!!authToken} timeout={Config.IDLE_TIME_OUT} />
//         )}
//             </div>
//         </div>
//     );
// }


import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import "./layout.css";
import SideBar from "./sidebar";
import TopBanner from "./topbanner";
import Config from "../../environment"; 

import { authTokenSelector } from "../../store/selectors/auth.selector";
import { useSelector } from "react-redux";
import Timer from "./Timer";

export default function Layout({ children }) {
    const authToken = useSelector(authTokenSelector);
    const navigate = useNavigate();

    useEffect(() => {
        if (authToken === null) {
            navigate("/login");
        }
    }, [authToken, navigate]);

    useEffect(() => {
        const disableContextMenu = (event) => {
            event.preventDefault();
            alert("Mouse right-click is disabled");
        };
        document.addEventListener("contextmenu", disableContextMenu);
        return () => {
            document.removeEventListener("contextmenu", disableContextMenu);
        };
    }, []);

    return (
        <div className="app-container">
            <div className="top-banner">
                <TopBanner />
            </div>
            <div className="render-section">
                <div className="left-section">
                    <SideBar />
                </div>
                <div className="right-section">
                    {children}
                </div>
            </div>
            <div>
                {authToken && (
                    <Timer isLoggedIn={!!authToken} timeout={Config.IDLE_TIME_OUT} />
                )}
            </div>
        </div>
    );
}
