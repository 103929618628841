

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Config from "../../environment";

const Timer = ({ isLoggedIn, timeout = Config.IDLE_TIME_OUT }) => {
  const [isIdle, setIsIdle] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoggedIn) return;

    let timeoutId;

    const resetTimer = () => {
      console.log("Activity detected. Resetting timer.");
      setIsIdle(false);
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        console.log("Timeout reached. User is idle.");
        setIsIdle(true);
      }, timeout);
    };

    const handleActivity = () => resetTimer();
    window.addEventListener("mousemove", handleActivity);
    window.addEventListener("keydown", handleActivity);

    resetTimer();

    return () => {
      console.log("Cleaning up timer");
      window.removeEventListener("mousemove", handleActivity);
      window.removeEventListener("keydown", handleActivity);
      clearTimeout(timeoutId);
    };
  }, [isLoggedIn, timeout]);

  useEffect(() => {
    if (isIdle && isLoggedIn) {
      console.log("Navigating to /login");
      navigate("/login");
    }
  }, [isIdle, navigate, isLoggedIn]);

  return null;
};

export default Timer;




