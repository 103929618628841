import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import NotFound from "pages/NotFound";
import Layout from "pages/layout/layout";
import RouterLoader from "./components/RouterLoader"
import TableList2 from "components/DynamicTable";
import DynamicForm2 from "components/DynamicForm2";


const SettingPageThree = React.lazy(() => import("pages/SettingPageThree"));
const SettingPageTwo = React.lazy(() => import("pages/SettingPageTwo"));
const SettingPageOne = React.lazy(() => import("pages/SettingPageOne"));
const Services = React.lazy(() => import("pages/Services"));
const Loan = React.lazy(() => import("pages/Loan"));
const CreditCards = React.lazy(() => import("pages/CreditCards"));
const Investments = React.lazy(() => import("pages/Investments"));
const Accounts = React.lazy(() => import("pages/Accounts"));
const Transaction = React.lazy(() => import("pages/Transaction"));
const MainDashboard = React.lazy(() => import("pages/MainDashboard"));
const Login = React.lazy(() => import("pages/Login/index"));
const Beneficiary = React.lazy(() => import("pages/Beneficiary"));
const AddBeneficiary = React.lazy(() => import("pages/Beneficiary/addBeneficiary"));
const DynamicForm = React.lazy(() => import("pages/DynamicForm"));
const Registration = React.lazy(() => import("pages/Registration"));
const Transactions = React.lazy(() => import("pages/Transaction/createtranaction"));
const SelfTransfers = React.lazy(() => import("pages/Transaction/selfTransfer"))
const Statement = React.lazy(() => import("pages/Statement"));
const LocationAtmBranch = React.lazy(() => import("pages/LocationAtmBranch"));
const Dashboard = React.lazy(() => import("pages/Dashboard"));
const NeftTransfer = React.lazy(() => import("pages/FTTransactions/neft"))
const FAQs = React.lazy(() => import("pages/FQAs"));
const RtgsTransfer = React.lazy(() => import("pages/FTTransactions/rtgs"));
const ImpsTransfer =React.lazy(() => import("pages/FTTransactions/imps"))
const LoanApplication = React.lazy(() => import("pages/Loans/loanapplication"));
const ScheduledTransfers = React.lazy(() => import("pages/Transaction/scheduleTransfer"))
const DashboardV2 = React.lazy(() => import("pages/Dashboard/dashboardv2")); 
const TransactionHistory = React.lazy(() => import("pages/Transaction/transactionsHistory"))
const Kyc = React.lazy(() => import("pages/Kyc"))
const Support = React.lazy(() => import("pages/support"))
const Request = React.lazy(() => import("pages/cheque"))
const Calculator = React.lazy(() => import("pages/Calculator"))
const Cheque = React.lazy(()=> import("pages/cheque"));
const Changepassword = React.lazy(()=> import("pages/changepassword"));
// const Transationlimit = React.lazy(()=> import("pages/transactionlimit"));
const LoanApp = React.lazy(()=> import("pages/Loans/allloanApplication"));
const TermAndCondtion = React.lazy(()=> import("pages/TermAndCondtion"));
const ViewFdRate = React.lazy(()=> import("pages/Investments/viewfdrate"));
const BranchSearchPage =React.lazy(()=> import("pages/Beneficiary/branchSearch"))
const NewBeneficiaryModal = React.lazy(()=>import("pages/Beneficiary/newBeneficiary"))
const Transactionlimit = React.lazy(()=>import("pages/Transaction-limit"))
const ForgotPassword = React.lazy(()=>import("pages/ForgotPassword"))
const Withinbanktransfer = React.lazy(()=>import("pages/FTTransactions/withinbanktransfer"))
const FTTransaction = React.lazy(() => import("pages/FTTransactions"))
const AddDynamicFormField = React.lazy(()=>import("pages/DynamicJson/dyamicform"))
const GetDynamicForm = React.lazy(()=>import("pages/DynamicJson/getdynamicform"))
const TermandCondition = React.lazy(() => import("pages/TermAndCondtion/terms&Condition"))
const Privacy = React.lazy(() => import("pages/TermAndCondtion/privecy&Plolicy"))
const Cards = React.lazy(() => import("pages/Cards"))
const ExchangeRates = React.lazy(() => import("pages/Login/exchangeRates"))
const Offers = React.lazy(()=>import("pages/Login/offers"))
const Feedback= React.lazy(() => import("pages/Login/feedback"))
const UpdateBeneficiary= React.lazy(() => import("pages/Beneficiary/updatebeneficiary"));
const BillPaymentPage = React.lazy(()=>import("pages/BillPaymentPage"));
const MobileRechargePage = React.lazy(() => import("pages/BillPaymentPage/MobileRechargePage"))
const DTHPage = React.lazy(() => import("pages/BillPaymentPage/DTHPage"))
const FastTag = React.lazy(() => import("pages/BillPaymentPage/FastTagRechargeForm"))
const Electricity = React.lazy(() => import("pages/BillPaymentPage/ElectricityBillPaymentForm"))
const BroadbandRechargeForm = React.lazy(()=>import("pages/BillPaymentPage/BroadbandReacharge"))
const WaterBillPaymentForm = React.lazy(()=>import("pages/BillPaymentPage/WaterBillPayment"))
const GasCylinderBookingForm = React.lazy(()=>import("pages/BillPaymentPage/GasCylinderBooking"))
const Tablefd = React.lazy(()=>import("pages/Investments/Tablefd"));
const Tablerd = React.lazy(()=>import("pages/Investments/Tablerd"));
const FD = React.lazy(() => import("pages/Investments/fd"))
const RD = React.lazy(()=>import("pages/Investments/rd"))

const DashboardChart = React.lazy(()=> import("pages/DashboardChart"));
const LoanApplicationList = React.lazy(() => import("pages/Loans/loanApplicationlist"))

const ProjectRoutes = () => {
  return (
    <React.Suspense fallback={<RouterLoader />}> 
      <Router>
        <Routes>
          <Route path="" element={<Login />} />
          <Route path="*" element={<NotFound />} />
          <Route path="/maindashboard" element={<MainDashboard />} />
          <Route path="/transaction" element={<Transaction />} />
          <Route path="/accounts" element={<Accounts />} />
          <Route path="/investments" element={<Layout><Investments /></Layout>} />
          <Route path="/creditcards" element={<CreditCards />} />
          <Route path="/loan" element={<Loan />} />
          <Route path="/services" element={<Services />} />
          <Route path="/settingpageone" element={<Layout><SettingPageOne /></Layout>} />
          <Route path="/settingpagetwo" element={<SettingPageTwo />} />
          <Route path="/settingpagethree" element={<SettingPageThree />} />
          <Route path="/beneficiary" element={<Layout><Beneficiary /></Layout>} />
          {/* <Route path="/dynamicform" element={<DynamicForm />} /> */}
          <Route path="/registration" element={<Registration />} />
          <Route path="/login" element={<Login />} />
          <Route path="/addbeneficiary" element={<AddBeneficiary />} />
          <Route path="/createtransaction" element={<Layout><Transactions /></Layout>} />
          <Route path="/selftranfer" element={<SelfTransfers />} />
          <Route path="/dashboardchart/statement/:accountNo" element={<Layout><Statement /></Layout>} />
          <Route path="/locationatmbranch" element={<LocationAtmBranch />} />
          <Route path="/fttransactions/neft" element={<Layout><NeftTransfer /></Layout>} />
          {/* <Route path="/dashboard" element={<Layout>  <Dashboard /></Layout>} /> */}
          <Route path="/faqs" element={<FAQs />} />
          {/* <Route path="/rtgs" element={<RtgsTransfer />} /> */}
          <Route path="/fttransactions/imps" element={<Layout><ImpsTransfer /></Layout>} />
          <Route path="/fttransactions/rtgs" element={<Layout><RtgsTransfer /></Layout>} />
          <Route path="/loan-list/loanapplication" element={<Layout><LoanApplication /></Layout>} />
          <Route path="/scheduletransfers" element={<ScheduledTransfers />} />
          <Route path="/dashboard" element={<Layout><DashboardV2 /></Layout>} />
          <Route path="/transactinshistory" element={<TransactionHistory />} />
          <Route path="/kyc" element={<Kyc />} />
          <Route path="/support" element={<Layout><Support /></Layout>} />
          <Route path="/request" element={<Layout><Request /></Layout>} />
          <Route path="/calculator" element={<Calculator />} />
          <Route path="/cheque" element={<Layout> <Cheque/> </Layout>}/> 
           <Route path="/changepassword" element={<Layout> <Changepassword/> </Layout>}/> 
           {/* <Route path="/transactionlimit" element={<Layout> <Transationlimit/> </Layout>}/> */}
           <Route path="/loanapp" element={ <LoanApp/>}/>
           <Route path="/termandcondition" element={ <TermAndCondtion/>}/>
           <Route path="/viewfdrate" element={ <ViewFdRate/> }/>
          <Route path="/branchSearch" element={<BranchSearchPage/>}/>
          <Route path="/beneficiary/newbeneficiary" element={<Layout><NewBeneficiaryModal/></Layout>}/>
          <Route path="/transaction-limit" element={<Layout><Transactionlimit/></Layout>}/>
          <Route path="forgot-password" element={<ForgotPassword />} />
          <Route path="/fttransactions/acctrf" element={<Layout><Withinbanktransfer/></Layout>}/>
          <Route path="/getdynamicform" element={<Layout><GetDynamicForm/></Layout>}/>
          <Route path="/adddynamicform" element={<Layout><AddDynamicFormField /></Layout>} />
          <Route path="/fttransactions" element={<Layout><FTTransaction /></Layout>} />
          <Route path="/dynamictable" element={<Layout><TableList2 /></Layout>} />
          <Route path="/dynamicform" element={<Layout><DynamicForm2 /></Layout>} />
          <Route path="/terms" element={<TermandCondition />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/cards" element={<Layout><Cards /></Layout>} />
          <Route path="/exchangerates" element={<ExchangeRates />} />
          <Route path="/offers" element={<Offers />} />
          <Route path="/feedback" element={<Feedback />} />
          <Route path="/updatebeneficiary/:id" element={<Layout><UpdateBeneficiary /></Layout>} />
          <Route path="/billpayments" element={<Layout><BillPaymentPage /></Layout>} />
          <Route path="billpayments/mobile-recharge" element={<Layout><MobileRechargePage /></Layout>} />
          <Route path="billpayments/dth-cable" element={<Layout><DTHPage /></Layout>} />
          <Route path="billpayments/fasttag" element={<Layout><FastTag /></Layout>} />
          <Route path="billpayments/electricity-bill" element={<Layout><Electricity /></Layout>} />
          <Route path="billpayments/broadband" element={<Layout><BroadbandRechargeForm /></Layout>} />
          <Route path="billpayments/water-bill" element={<Layout><WaterBillPaymentForm /></Layout>} />
          <Route path="billpayments/gas-cylinder" element={<Layout><GasCylinderBookingForm /></Layout>} />
          <Route path="/Tablefd" element={<Layout><Tablefd/></Layout>}/>
          <Route path="/Tablerd" element={<Layout><Tablerd/></Layout>}/>
          <Route path="/investments/fd" element={<Layout><FD/></Layout>}/>
          <Route path="/investments/rd" element={<Layout><RD/></Layout>}/>
          <Route path="/DashboardChart" element={<Layout><DashboardChart/></Layout>} />
          <Route path="/loan-list" element={<Layout><LoanApplicationList/></Layout>} />

        

        </Routes>
      </Router>
    </React.Suspense>
  ); 
};
export default ProjectRoutes;
