// import http from "./http.service";
// import config from "./constant";
// import { SET_AUTH_TOKEN, SET_LOGIN_DETAILS, SET_USER_LIST, SET_ACCOUNT_LIST, SET_BENE_ACCOUNT_LIST,SET_USER_PROFILE  } from "../store/constants";
// import { store } from "../store";
// // import { getErrorMessage } from './utils/errorUtils';


// // const postRequest = async (data,api) => {

// //   const { auth, app } = store.getState();

// //   try {
// //     const reqParams = {
// //       cust_id:app?.loginDetails?.custId,
// //       ...data
// //     };
// //     const response = await http.post(api, reqParams);
// //     if(data.operation_code=="ACC_LIST"){
// //       store.dispatch({ type: SET_ACCOUNT_LIST, payload: response.data.response});

// //     }
// //     else if(data.operation_code=="CUST_PROFILE"){
// //       store.dispatch({ type: SET_USER_PROFILE, payload: response.data.response });

// //     }

// //     else if(data.operation_code=="GET_ALL"){

// //       if(response.data.code=="Error"){
// //         store.dispatch({ type: SET_BENE_ACCOUNT_LIST, payload: [] });
// //       }
// //       else{
// //         store.dispatch({ type: SET_BENE_ACCOUNT_LIST, payload: response.data });
// //       }

// //     }
// //     return { status: response.status, data: response.data };

// //     dis
// //   } catch (err) {
// //     return Promise.reject(err);
// //   }
// // };

// const postRequest = async (data, api) => {
//   const { auth, app } = store.getState();

//   try {
//     const reqParams = {
//       cust_id: app?.loginDetails?.custId,
//       ...data,
//     };

//     const response = await http.post(api, reqParams);

//     if (response.data.code === "Error") {
//       // Standardize error handling for API-level errors
//       throw new Error(response.data.message || "Something went wrong.");
//     }

//     // Handle operation-specific actions
//     switch (data.operation_code) {
//       case "ACC_LIST":
//         store.dispatch({ type: SET_ACCOUNT_LIST, payload: response.data.response });
//         break;
//       case "CUST_PROFILE":
//         store.dispatch({ type: SET_USER_PROFILE, payload: response.data.response });
//         break;
//       case "GET_ALL":
//         const beneAccounts =
//           response.data.code === "Error" ? [] : response.data;
//         store.dispatch({ type: SET_BENE_ACCOUNT_LIST, payload: beneAccounts });
//         break;
//       default:
//         break;
//     }

//     return { status: response.status, data: response.data };
//   } catch (err) {
//     // Process the error and return a clean error message
//     console.error("Error in postRequest:", err);
//     const errorMessage = err.response?.data?.message || "An unexpected error occurred.";
//     throw new Error(errorMessage);
//   }
// };

// // errorUtils
// // const postRequest = async (data, api) => {
// //   const { auth, app } = store.getState();

// //   try {
// //     const reqParams = {
// //       cust_id: app?.loginDetails?.custId,
// //       ...data,
// //     };

// //     const response = await http.post(api, reqParams);

// //     if (response.data.code === "Error") {
// //       // Standardize error handling for API-level errors
// //       throw new Error(response.data.message || "Something went wrong.");
// //     }

// //     // Handle operation-specific actions
// //     switch (data.operation_code) {
// //       case "ACC_LIST":
// //         store.dispatch({ type: SET_ACCOUNT_LIST, payload: response.data.response });
// //         break;
// //       case "CUST_PROFILE":
// //         store.dispatch({ type: SET_USER_PROFILE, payload: response.data.response });
// //         break;
// //       case "GET_ALL":
// //         const beneAccounts =
// //           response.data.code === "Error" ? [] : response.data;
// //         store.dispatch({ type: SET_BENE_ACCOUNT_LIST, payload: beneAccounts });
// //         break;
// //       default:
// //         break;
// //     }

// //     return { status: response.status, data: response.data };
// //   } catch (err) {
// //     // Process the error and return a clean error message
// //     console.error("Error in postRequest:", err);
// //     const errorMessage = err.response?.data?.message || "An unexpected error occurred.";
// //     throw new Error(errorMessage);
// //   }
// // };

// const postRegistration = async (data,api) => {


//   try {
//     const reqParams = {
//       ...data
//     };
//     const response = await http.post(api, reqParams);
//     return { status: response.status, data: response.data };

//     dis
//   } catch (err) {
//     return Promise.reject(err);
//   }
// };

// const RegistrationService = {
//   postRequest,


//   postRegistration
// };

// export default RegistrationService;
import http from './http.service';
import { store } from '../store';
import { SET_AUTH_TOKEN, SET_LOGIN_DETAILS, SET_USER_LIST, SET_ACCOUNT_LIST, SET_BENE_ACCOUNT_LIST,SET_USER_PROFILE } from '../store/constants';

const postRequest = async (data, api) => {
  const { app } = store.getState();
  try {
    const reqParams = {
      cust_id: app?.loginDetails?.custId,
      ...data,
    };
    const response = await http.post(api, reqParams);

    // Dispatch different actions based on operation_code
    if (data.operation_code === 'ACC_LIST') {
      store.dispatch({ type: SET_ACCOUNT_LIST, payload: response.data.response });
    } else if (data.operation_code === 'CUST_PROFILE') {
      store.dispatch({ type: SET_USER_PROFILE, payload: response.data.response });
    } else if (data.operation_code === 'GET_ALL') {
      if (response.data.code === 'Error') {
        store.dispatch({ type: SET_BENE_ACCOUNT_LIST, payload: [] });
      } else {
        store.dispatch({ type: SET_BENE_ACCOUNT_LIST, payload: response.data });
      }
    }

    return { status: response.status, data: response.data };
  } catch (err) {
    // Common error handling without a utility
    let errorMessage = 'An error occurred while processing your request. Please try again later.';

    if (err.response) {
      // If the error is from an API response
      errorMessage = err.response?.data?.message || errorMessage;
    } else if (err.message) {
      // For network or client-side errors
      errorMessage = err.message;
    }

    throw new Error(errorMessage); // Throw the error with a descriptive message
  }
};

const postRegistration = async (data, api) => {
  try {
    const reqParams = {
      ...data,
    };
    const response = await http.post(api, reqParams);
    return { status: response.status, data: response.data };
  } catch (err) {
    // Common error handling
    let errorMessage = 'An error occurred while processing your request. Please try again later.';

    if (err.response) {
      // If the error is from an API response
      errorMessage = err.response?.data?.message || errorMessage;
    } else if (err.message) {
      // For network or client-side errors
      errorMessage = err.message;
    }

    throw new Error(errorMessage);
  }
};

const RegistrationService = {
  postRequest,
  postRegistration,
};

export default RegistrationService;