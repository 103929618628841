const configuration = {
  ENCRYPT_KEY: "20E38214tC181205",
  
//SERVICE_URL: "https://ui.azaniabank.co.tz/api/",
//Admin  portal
//SERVICE_URL: "https://digital.azaniabank.co.tz:9090/api/",
// SERVICE_URL: "http://localhost:8080/tvrtr",
SERVICE_URL: "https://router.techverves.biz/tvrtr",
//  SERVICE_URL: "https://router.techverves.biz/tvrtr",
//   SERVICE_URL: "http://10.158.111.67:3020/api",
  // SERVICE_URL:"https://bizociate.in/apigateway/admin",
  // SERVICE_URL: "https://172.16.2.21/apigateway/admin",
  // SERVICE_URL: "https://bizociate.in/apigateway_bcb/admin",
  GOOGLE_MAPS_API_KEY: "AIzaSyD94dYAJU4hkOyMi3mz6QGWxUsqkAx-9Ag",
  COORDINATES: { lat: -17.8252, lng: 31.0335 },
  COUNTRY_CODE: "91",
  ENCRYPT_ENABLE:false,
  IDLE_TIME_OUT:300000 ,
  
};

export default { ...configuration };
