import React, { useState, useEffect } from "react";
import { Button, Row, Col, Avatar, Typography, Drawer } from "antd";
import { MenuOutlined, LogoutOutlined, BellOutlined, CloseOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import './topbanner.css';
import Sidebar from './sidebar';
import { useSelector, useDispatch } from 'react-redux';
import { loginDetailsSelector } from "store/selectors/app.selector";
import RegistrationService from "../../services/registration.service";
import config from "../../../src/services/constant";
import { OPERTION_CODE } from "Constants/enum";
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Hidden } from "@mui/material";
const { Text } = Typography;
import Notification from "components/Notifications";

const TopBanner = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loginDetails = useSelector(loginDetailsSelector);
  const [imageSrc, setImageSrc] = useState("");
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);


  useEffect(() => {
    const fetchProfileDetails = async () => {
      try {
        const payload = {
          operation_code: OPERTION_CODE.CUST_PROFILE,
          cust_id: loginDetails?.custId || "",
        };
        const response = await RegistrationService.postRequest(
          payload,
          config.USER_PROFILE
        );

        if (response.data.response) {
          const imageBase64 = response.data.response.documentImage;
          if (imageBase64) {
            const imageSrc = `data:image/jpeg;base64,${imageBase64}`;
            setImageSrc(imageSrc);
          }
        }
      } catch (err) {
        console.error("API call failed", err);
      }
    };

    if (loginDetails?.custId) {
      fetchProfileDetails();
    }
  }, [loginDetails]);

  const [visible, setVisible] = useState(false);


  const toggleDrawer = () => setDrawerOpen(!drawerOpen);
  const toggleMenu = () => setMenuOpen(!menuOpen);

  const onLogout = () => {
    dispatch({ type: "USER_LOGOUT" });
    navigate("/login");
  };

  const currentDateTime = new Date().toLocaleString();

  return (
    <div className="fixed top-0 left-0 right-0 flex items-center justify-between w-full h-16 bg-white shadow px-4 z-50">

      <div className="flex items-center space-x-4">
        <button
          onClick={toggleDrawer}
          className="text-gray-800 text-2xl lg:hidden"
          aria-label="Toggle Sidebar"
        >
          <MenuOutlined />
        </button>
        <div className="hidden lg:flex items-center">
          <img src="images/techverve.png" alt="Logo" className="h-10" />
        </div>
      </div>

      <Typography sx={{ fontFamily: 'Poppins, sans-serif' }} className="sm:text-base md:text-lg lg:text-xl p-5">
        Welcome, {loginDetails?.name || "User"}!
      </Typography>

      <div className="flex items-center space-x-3">
        <Avatar src={imageSrc} className="w-10 h-10 hidden lg:block" />

        <div className="flex flex-col items-start">
          <span className="poppins-font hidden lg:block">
            {loginDetails?.name || "User"}
          </span>
          <Text className="text-sm text-gray-600 hidden lg:block">
            {currentDateTime}
          </Text>
        </div>


        <Button
          type="primary"
          onClick={onLogout}
          icon={<LogoutOutlined className="mr-2" />}
          className="hidden lg:block"
          style={{
            background: "linear-gradient(135deg, #1675b9 0%, #52c0d9 100%)",
          }}
        >
          Logout
        </Button>

        {/* <BellOutlined className="text-gray-800 text-xl hidden lg:block" /> */}
        <Notification />


        <Hidden lgUp>
          <IconButton onClick={toggleMenu} aria-label="Open Menu" className="lg:hidden">
            <MoreVertIcon className="text-gray-800 text-2xl" />
          </IconButton>
        </Hidden>
      </div>


      <Drawer
        title={null}
        placement="left"

        closable={false}
        onClose={toggleDrawer}
        open={drawerOpen}
        width="70%"


      >

        <button
          onClick={toggleDrawer}
          className="absolute top-2 right-2 text-2xl"
          aria-label="Close Drawer"
        >
          <CloseOutlined />
        </button>


        <div className="flex justify-start items-start p-5">
          <Typography className="text-lg font-semibold">Menu</Typography>
        </div>

        <Sidebar closeDrawer={toggleDrawer} />
      </Drawer>


      {menuOpen && (
        <div className="absolute top-16 right-4 w-49 bg-white shadow-lg p-4 z-50 overflow-y-auto">
          <div className="flex items-center space-x-2 mb-4">
            <Avatar src={imageSrc} className="w-8 h-8" />
            <span className="poppins-font flex justify-center items-center" style={{ fontFamily: 'Poppins, sans-serif', display: 'flex', flexDirection: 'column' }}>
              {loginDetails?.name || "User"}
              <Text className="text-sm text-gray-600">{currentDateTime}</Text>
            </span>
          </div>
          <div className="flex justify-center items-center">
            <Button
              type="primary"
              onClick={onLogout}
              icon={<LogoutOutlined className="mr-3" />}
              style={{
                background: "linear-gradient(135deg, #1675b9 0%, #52c0d9 100%)",
              }}
            >
              Logout
            </Button>
            <BellOutlined className="text-gray-800 text-xl ml-5" />
          </div>

        </div>
      )}

    </div>


  );
};

export default TopBanner;

