export default {

  LOGIN: "/auth/login",
  CHANGE_PASSWORD: "/auth/pwdchange",
  REFRESH_TOKEN: "/user/refreshToken",
  REGISTRATION: "/user/register",
  VERIFY_REGISTRATION: "/user/validate",
  VALIDATE_OTP: "/validate/otp",
  USER_PROFILE:'/common/req',

// Dashboard
ACCOUNT_DETAILS:"/common/req",
CONFIRM_OTP:"/validate/transotp",
MINI_STATEMENT:"common/req",
VALIDATE_MOB:"/common/req",
LOAN_APP:"/common/req",

SAVE_DYNAMIC_FORM: "/user/saveform",
COMM_REQ: "/common/req",

ROLE:"/role",
USER :"/user",
DASHBOARD:"/dashboardcount",
REPORT_LIST:"/reportlist",
TEAMS:"/teammates",


ADD_JSON_FORM:"/user/saveform",
GET_LAON_PRODUCT:"/formdata",
HOLIDAY_LIST:"/holidaymaintenance",
REWARD_RULES_LIST:"/loyaltyrewards",
SUPPORT_LIST:"/common/req",
TRANSACTION_LIMIT:"/common/req",
TRANSACTION_ENABLE_DISABLE:"/transactionenabledisable",
Beneficiary_GetBy_Id:"/getById",
FDRD:'/common/req',
CHANGE_PWD:'/common/req',
TRANSACTIONS_REPORT :'reportlist',
SECURITYQUESTION :'/securityQuestion',
SECURITYQUESTIONCREATE:'/common/req',
ADMIN_LIMIT:'/transactionlimit'



};
