// import React, { useState, useEffect } from "react";
// import { Link, useLocation, useNavigate } from "react-router-dom";
// import { Img, Text, Line } from "components";

// const Sidebar = ({ className, closeDrawer }) => { // Receive closeDrawer as a prop
//     const location = useLocation();
//     const navigate = useNavigate();
//     const [selectedTab, setSelectedTab] = useState('');

//     useEffect(() => {
//         const currentPath = location.pathname;
//         const defaultTab = menuItems.find(item => currentPath.includes(item.path))?.name || 'Dashboard';
//         setSelectedTab(defaultTab);
//     }, [location.pathname]);

//     const handleTabClick = (path, tabName) => {
//         setSelectedTab(tabName);
//         navigate(path); 
//         if (closeDrawer) closeDrawer(); 
//     };
 
//     const menuItems = [
//         { name: 'Dashboard', icon: 'images/homenew.png', path: '/dashboard' },
//         { name: 'Transaction Limit', icon: 'images/transaction-limit.png', path: '/transaction-limit' },
//         { name: 'Transactions', icon: 'images/transaction.png', path: '/fttransactions' },
//         { name: 'Beneficiaries', icon: 'images/beneficiarynew.png', path: '/beneficiary' },
//         { name: 'Loan Application', icon: 'images/loan.png', path: '/loanapplication' },
//         { name: 'Cards', icon: 'images/debit.png', path: '/cards' },
//         { name: 'Investments', icon: 'images/investmentnew.png', path: '/investments' },
//         { name: 'Requests', icon: 'images/requestnew.png', path: '/request' },
//         { name: 'Settings', icon: 'images/settingnew.png', path: '/settingpageone' },
//         { name: 'Support', icon: 'images/customer-service.png', path: '/support' },
//         // { name: 'Add Form', icon: 'images/settingnew.png', path: '/adddynamicform' },
//         // { name: 'Loading Form', icon: 'images/customer-service.png', path: '/getdynamicform' },
//         // { name: 'Dynamic Form', icon: 'images/customer-service.png', path: '/dynamicform' },


         



//         // { name: 'Accounts', icon: 'images/img_user.svg', path: '/accounts' },
//         // { name: 'Investments', icon: 'images/img_map.svg', path: '/investments' },
//         // { name: 'Credit Cards', icon: 'images/img_file.svg', path: '/creditcards' },
//         // { name: 'Loans', icon: 'images/img_question.svg', path: '/loan' },
//         // { name: 'Services', icon: 'images/img_service1.svg', path: '/services' },
//         // { name: 'My Privileges', icon: 'images/img_lightbulb.svg', path: '/my-privileges' },
//         // { name: 'Table List', icon: 'images/img_lightbulb.svg', path: '/tablelist' },
//         // { name: 'Table List2', icon: 'images/img_settings_25X25.svg', path: '/tablelist1' }
//     ];

//     return (
//         <aside className={className}>
//             <div className="sidebarpadding flex flex-col gap-[50px] items-start justify-start pr-[40px] py-[3vh] h-full w-full pr-[100px]" >
//                 <div className="flex flex-col gap-[23px] justify-start w-full" >
//                     {menuItems.map((item) => (
//                         <div
//                             key={item.name}
//                             className="w-full"
//                             onClick={() => handleTabClick(item.path, item.name)}
//                         >
//                             <div className="relative flex items-center cursor-pointer w-full">
//                                 {selectedTab === item.name && (
//                                     <Line className="bg-indigo_600 h-[60px] rounded-br-[10px] rounded-tr-[10px] w-[6px] absolute left-0" />
//                                 )}
//                                 <Img
//                                     src={item.icon}
//                                     className="h-[25px] ml-[20px] w-[25px]"
//                                     alt={item.name}
//                                 />
//                                 <Text
//                                     className={`font-inter font-medium ml-[20px] text-left w-[auto] ${selectedTab === item.name ? 'text-indigo_600' : 'text-gray-600'
//                                         }`}
//                                     as="h5"
//                                     variant="h5"
//                                 >
//                                     {item.name}
//                                 </Text>
//                             </div>
//                         </div>
//                     ))}
//                 </div>
//             </div>
//         </aside>
//     );
// };

// export default Sidebar;



import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Text, Line } from "components";
import { 
  FaThLarge, FaExchangeAlt, FaReceipt, FaFileInvoice, FaUsers, 
  FaHandHoldingUsd, FaCreditCard, FaChartLine, FaEnvelopeOpenText, 
  FaCogs, FaLifeRing 
} from "react-icons/fa";

const Sidebar = ({ className, closeDrawer }) => { 
        const location = useLocation();
        const navigate = useNavigate();
        const [selectedTab, setSelectedTab] = useState('');
    
    

        // useEffect(() => {
        //     const currentPath = location.pathname;
        //     const matchedTab = menuItems.find((item) => currentPath.includes(item.link));
        //     setSelectedTab(matchedTab?.name || '');
        // }, [location.pathname]);

        useEffect(() => {
            const currentPath = location.pathname;
            const matchedTab = menuItems.find(
                (item) => currentPath === item.link || currentPath.startsWith(item.link)
            );
            setSelectedTab(matchedTab?.name || '');
        }, [location.pathname]);
        
        
        
    
        
        const handleTabClick = (path, tabName) => {
            setSelectedTab(tabName); 
            navigate(path); 
            if (closeDrawer) closeDrawer(); 
        };
    
     

    const menuItems = [
        { name: 'Dashboard', icon: <FaThLarge size={20} />, link: '/dashboardchart' },
        { name: 'Transaction Limit', icon: <FaExchangeAlt size={20} />, link: '/transaction-limit' },
        { name: 'Transactions', icon: <FaReceipt size={20} />, link: '/fttransactions' },
        { name: 'Bill Payments', icon: <FaFileInvoice size={20} />, link: '/billpayments' },
        { name: 'Beneficiaries', icon: <FaUsers size={20} />, link: '/beneficiary' },
        { name: 'Loan Applications', icon: <FaHandHoldingUsd size={20} />, link: '/loan-list' },
        { name: 'Cards', icon: <FaCreditCard size={20} />, link: '/cards' },
        { name: 'Investments', icon: <FaChartLine size={20} />, link: '/investments' },
        { name: 'Requests', icon: <FaEnvelopeOpenText size={20} />, link: '/request' },
        { name: 'Settings', icon: <FaCogs size={20} />, link: '/settingpageone' },
        { name: 'Support', icon: <FaLifeRing size={20} />, link: '/support' },
    ];



return (
    <aside className={className}>
        <div className="sidebarpadding flex flex-col gap-[10px] items-start justify-start pr-[60px] py-[2vh] h-full w-[280px]">
            <div className="flex flex-col gap-[15px] justify-start w-full">
                {menuItems.map((item) => (
                    <div
                        key={item.name}
                        className="w-full relative cursor-pointer"
                        onClick={() => handleTabClick(item.link, item.name)}
                    >
                        {/* <div
                            className={`flex items-center justify-start pl-[20px] w-[190px] h-[40px] transition-all duration-300 
                                ${selectedTab === item.name ? 'bg-gradient-to-r from-[#1b71f3] to-[#6bdfdb] text-white' : 'group-hover:bg-gradient-to-r group-hover:from-[#1b71f3] group-hover:to-[#6bdfdb] group-hover:text-white'} 
                                rounded-lg overflow-hidden mx-auto transform ${selectedTab === item.name ? 'scale-100' : 'group-hover:scale-y-110'}`} 
                        >
                            <div className="flex items-center justify-start">
                                <span
                                    className={`text-[${selectedTab === item.name ? '#ffffff' : '#6b7280'}] transition-all duration-300`}
                                >
                                    {item.icon}
                                </span>
                                <Text
                                    className={`ml-[15px] text-left w-auto ${selectedTab === item.name ? 'text-white' : 'text-gray-600'} transition-all duration-300`}
                                    style={{ fontFamily: 'Poppins, sans-serif', fontSize: '16px', fontWeight: '500' }}
                                    as="h5"
                                    variant="h5"
                                >
                                    {item.name}
                                </Text>
                            </div>
                        </div> */}
                        <div
    className={`flex items-center justify-start pl-[20px] w-[190px] h-[40px] transition-all duration-300 
        ${selectedTab === item.name ? 'bg-gradient-to-r from-[#1b71f3] to-[#6bdfdb] text-white' : 'text-gray-600'} 
        rounded-lg overflow-hidden mx-auto`}
>
    <div className="flex items-center justify-start">
        <span className={`${selectedTab === item.name ? 'text-white' : 'text-gray-600'} transition-all duration-300`}>
            {item.icon}
        </span>
        <Text
            className={`ml-[15px] ${selectedTab === item.name ? 'text-white' : 'text-gray-600'} transition-all duration-300`}
            style={{ fontFamily: 'Poppins, sans-serif', fontSize: '16px', fontWeight: '500' }}
            as="h5"
            variant="h5"
        >
            {item.name}
        </Text>
    </div>
</div>

                    </div>
                ))}
            </div>
        </div>
    </aside>
);


};
    export default Sidebar;


 


